import * as _ from "lodash";
import { IElementUpdate } from "../../interface/IElementUpdate";
import { IForm } from "../../interface/IForm";
import { IFormElement } from "../../interface/IFormElement";
import { IFormGroup } from "../../interface/IFormGroup";
import {
    ValidationHelper
} from "../Forms/Validation";
import {
    FormElementHelper
} from "./Element";
import { UtilHelper } from "./index";
import { IOption } from "../../interface/IOption";
import {
    UI_DYNAMIC_CONFIG_ELEMENT_TYPES,
    UI_ELEMENT_TYPES
} from "../../../config";
import { IDynamiceConfiguration } from "../../interface/IDynamicConfiguration";
import { ImageTextHelper } from "../Forms/BuildForm/ImageText";
import { MultiSelectHelper } from "../Forms/BuildForm/SelectInput/MultiSelect";
import { SingleSelectHelper } from "../Forms/BuildForm/SelectInput/SingleSelect";
import { BuildFormValidationHelper } from "../Forms/BuildForm/Validation";

// Parent Child Element Util
export class ParentChildElementUtil {

    private validationHelper: ValidationHelper;
    private elementHelper: FormElementHelper;
    private utilHelper: UtilHelper;
    private imageTextHelper: ImageTextHelper;
    private multiSelect: MultiSelectHelper;
    private singleSelect: SingleSelectHelper;
    private formValidationHelper: BuildFormValidationHelper

    constructor() {
        this.validationHelper = new ValidationHelper();
        this.elementHelper = new FormElementHelper();
        this.utilHelper = new UtilHelper();
        this.multiSelect = new MultiSelectHelper();
        this.singleSelect = new SingleSelectHelper();
        this.imageTextHelper = new ImageTextHelper();
        this.formValidationHelper = new BuildFormValidationHelper();
    }
    /**
     * Find parent element
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IForms[]} forms.
     */
    public findParentElements = async (forms: IForm[], element: IElementUpdate): Promise<IForm[]> => {
        const Forms: IForm[] = forms.map((form: IForm) => ({
            ...form, groups:
                form.groups.map((group: IFormGroup) => ({
                    ...group,
                    elements: group.elements.map((ele: IFormElement) => ({
                        ...ele
                    }))
                }))
        }));

        for (let iForm = 0; iForm < Forms.length; iForm++) {
            const Groups = Forms[iForm].groups;
            for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) {
                const Elements = Groups[iFormGroup].elements;
                for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) {
                    const FormElement = Elements[iFormElement];
                    if (!_.isEqual(FormElement.parent_elements.length, 0)
                        && (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT) ||
                            _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) ||
                            _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.THUMBNAIL) ||
                            _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD) ||
                            _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD))) {
                        const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(forms, FormElement.parent_elements);

                        if (IsAnyParentEmpty) {

                            let errorMessage = "";
                            if (FormElement.is_required) {
                                errorMessage = await this.validationHelper.validate(FormElement,
                                    _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) ? "" : null);
                            }

                            if (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)) {
                                if (!_.isNull(FormElement.value)) {
                                    if (FormElement.is_multi) {
                                        const UpdatedValue = FormElement.value;
                                        const Values = UpdatedValue.filter((option: IOption) => option.highlightOption);
                                        FormElement.highlight_option_error = _.isEqual(Values.length, 0) ? "" : "Please remove options in red as they no longer exist.";
                                    } else {
                                        FormElement.highlight_option_error = !FormElement.value.highlightOption ? "" : "Please remove the option as it is no longer exist.";
                                    }
                                } else {
                                    FormElement.highlight_option_error = "";
                                }
                            }

                            let elementValue: any = _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) ? "" : null;
                            if (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)) {
                                elementValue = ImageTextHelper.DEFAULT_VALUE;
                            }

                            Elements[iFormElement] = {
                                ...FormElement,
                                disabled: true,
                                value: elementValue,
                                dataSource: [],
                                hideBasedOnParent: _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.THUMBNAIL)
                                    || _.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD),
                                error: errorMessage,
                                is_visible: FormElement.is_visible,
                                isValid: _.isEqual(errorMessage.length, 0) && _.isEqual(FormElement.highlight_option_error.length, 0),
                                touched: _.isEqual(FormElement.id, element.formElement.id) ? true : FormElement.touched,
                            };
                        }
                        else {

                            let errorMessage = "";
                            if (Elements[iFormElement].is_required) {
                                errorMessage = await this.validationHelper.validate(Elements[iFormElement],
                                    Elements[iFormElement].value);
                            }

                            if (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)) {
                                if (!_.isNull(Elements[iFormElement].value) && !_.isUndefined(Elements[iFormElement].value)) {
                                    if (Elements[iFormElement].is_multi) {
                                        const UpdatedValue = Elements[iFormElement].value;
                                        const Values = UpdatedValue.filter((option: IOption) => option?.highlightOption);
                                        Elements[iFormElement].highlight_option_error = _.isEqual(Values?.length, 0) ? "" : "Please remove options in red as they no longer exist.";
                                    } else {
                                        Elements[iFormElement].highlight_option_error = !Elements[iFormElement]?.value?.highlightOption ? "" : "Please remove the option as it is no longer exist.";

                                    }
                                } else {
                                    Elements[iFormElement].highlight_option_error = "";
                                }
                            }

                            Elements[iFormElement] = {
                                ...Elements[iFormElement],
                                error: errorMessage,
                                isValid: _.isEqual(errorMessage.length, 0) && _.isEqual(Elements[iFormElement].highlight_option_error.length, 0),
                                touched: _.isEqual(Elements[iFormElement].id, element.formElement.id) ? true : Elements[iFormElement].touched,
                            };
                        }
                    }
                    else {
                        Elements[iFormElement] = {
                            ...Elements[iFormElement],
                            touched: _.isEqual(Elements[iFormElement].id, element.formElement.id) ? true : Elements[iFormElement].touched,
                        };
                    }
                }
            }
        }
        return Forms;
    }

    /**
     * Find child elements
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IForms[]} forms.
     */
    public findChildElements = async (forms: IForm[], element: IElementUpdate)
        : Promise<IForm[]> => {
        const Forms: any = [];
        for (const form of forms) {
            const FormGroups: IFormGroup[] = await this.apiGetFormGroups(forms, form.groups, element);
            Forms.push({
                ...form,
                groups: FormGroups
            });
        }
        return Forms;
    }

    /**
     * Find child elements
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IForms[]} forms.
     */
    public findChildLoadingCell = (forms: IForm[], element: IElementUpdate)
        : any => {
        const Forms: any = [];
        for (const form of forms) {
            const FormGroups: IFormGroup[] = this.loadingCellsFormGroups(forms, form.groups, element);
            Forms.push({
                ...form,
                groups: FormGroups
            });
        }
        return Forms;
    }

    /**
     * Find form groups
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IFormGroup[]} FormGroups.
     */
    private apiGetFormGroups = async (forms: IForm[], formGroups: IFormGroup[], element: IElementUpdate):
        Promise<IFormGroup[]> => {
        const FormGroups: IFormGroup[] = [];
        for (const formGroup of formGroups) {
            const FormElementArray = [];
            for (const formElement of formGroup.elements) {
                const Element: IFormElement = await this.apiUpdateElement(forms, formElement, element);
                FormElementArray.push(Element);
            }
            FormGroups.push({
                ...formGroup,
                elements: FormElementArray
            });
        }
        return FormGroups;
    }

    /**
     * Find form groups
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IFormGroup[]} FormGroups.
     */
    private loadingCellsFormGroups = (forms: IForm[], formGroups: IFormGroup[], element: IElementUpdate):
        any => {
        const FormGroups: IFormGroup[] = [];
        for (const formGroup of formGroups) {
            const FormElementArray = [];
            for (const formElement of formGroup.elements) {
                const Element: IFormElement = this.loadingCellsUpdateElement(forms, formElement, element);
                FormElementArray.push(Element);
            }
            FormGroups.push({
                ...formGroup,
                elements: FormElementArray
            });
        }
        return FormGroups;
    }

    /**
     * Find if the current element is a parent and call the API
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IFormElement} Element.
     */
    private apiUpdateElement = async (forms: IForm[], formElement: IFormElement, element: IElementUpdate)
        : Promise<IFormElement> => {
        let Element: IFormElement = { ...formElement };
        if (!_.isEqual(Element.parent_elements.length, 0) && !_.isEqual(Element.parent_elements.indexOf(element.formElement.id), -1)) {
            const ElementValue = this.utilHelper.isEmptyValue(element.value) ? null : element.value;
            let dataSource = [];
            let elementValue = null;
            const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(forms, Element.parent_elements);

            let dynamicFieldValues: any = [];

            let callAPI = !_.isNull(ElementValue);
            if (!element.formElement.is_required) {
                callAPI = true;
            }

            if (callAPI && !this.utilHelper.isEmptyValue(Element.api_url)
                && !IsAnyParentEmpty) {
                try {
                    if (!_.isNull(formElement.configuration_api_url)) {
                        const ValidationData = await this.elementHelper.getConfigurationData(forms, Element);
                        Element = {
                            ...Element,
                            ...ValidationData.data
                        };
                    }
                    const APIData = await this.elementHelper.getAPIData(forms, Element);

                    dataSource = _.isArray(APIData) ? APIData : [];
                    if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                    || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.CHIP)
                    || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD)
                    || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID)){
                        elementValue = APIData;
                    }
                    else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)) {
                        elementValue = this.imageTextHelper.getAPIDataValue(APIData);
                    }
                    if (Element.allow_all && !_.isEqual(dataSource.length, 0)) {
                        dataSource.unshift(UtilHelper.allOptionLblVal);
                    }
                    Element.dataSource = dataSource;
                    Element.apiError = "";
                }
                catch (error: any) {
                    dataSource = [];
                    Element.dataSource = [];
                    Element.apiError = this.utilHelper.getErrorMessage(error);
                    //** Assign API returned error to field apiError */
                    if (!_.isNull(Element.is_dataset) && _.isEqual(Element.is_dataset, 1) && _.isEqual(error.response.status, 401)) {
                        Element.apiError = this.utilHelper.getErrorMessage(error);
                    }
                }
                Element.hideBasedOnParent = false;
            }

            let dynamicElement = null;

            if (!_.isNull(ElementValue) && !_.isNull(Element.dynamic_configuration)
                && _.isEqual(Element.dynamic_configuration.elementType, UI_DYNAMIC_CONFIG_ELEMENT_TYPES.METRICS) && !_.isEqual(dataSource.length, 0)) {
                const DynamicConfiguration = Element.dynamic_configuration;
                dynamicElement = DynamicConfiguration.data.find((ele: any) => _.isEqual(ele.parentValue, ElementValue.value));

                if (!_.isUndefined(dynamicElement)) {
                    const Children: IDynamiceConfiguration[] = (dynamicElement.children).map((child: any) => ({ ...child }));
                    dynamicFieldValues = Children.filter((child: IDynamiceConfiguration) => child.required);

                    dynamicFieldValues = dynamicFieldValues.map((child: IDynamiceConfiguration) => ({
                        ...child,
                        value: child.default ? child.value : null
                    }));
                }

                Element.value = { label: "dummy", value: "dummy" };
                for (const dynamicEl of dynamicFieldValues) {
                    if ((_.isNull(dynamicEl.value) || _.isNull(dynamicEl.sortby)) && dynamicEl.required) {
                        Element.value = null;
                    }
                }
            }


            let errorMessage = "";
            if (Element.is_required) {
                errorMessage = await this.validationHelper.validate(Element,
                    null);
            }

            if (Element.is_required && !_.isNull(Element.dynamic_configuration)
                && _.isEqual(Element.dynamic_configuration.elementType, UI_DYNAMIC_CONFIG_ELEMENT_TYPES.METRICS)) {
                errorMessage = await this.validationHelper.validate(Element,
                    Element.value);
            }


            if (formElement.is_multi && !_.isNull(formElement.selection_limit)) {
                Element = this.elementHelper.updateSelectionLimit(Element, []);
                dataSource = Element.dataSource;
            }

            const APIValueURL = formElement.api_value_url || "";

            if (!_.isEmpty(APIValueURL)) {
                const APIValueData = await this.elementHelper.getAPIValueData(forms, Element);
                Element = formElement.is_multi ? this.multiSelect.getMultiSelectAPIValue(Element, APIValueData) :
                    this.singleSelect.getSingleSelectAPIValue(Element, APIValueData);

                errorMessage = await this.validationHelper.validate(formElement, Element.value);
                elementValue = Element.value;
            }

            let isValidElement = _.isEqual(errorMessage.length, 0);

            if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)) {
                const {
                    highlightOptionError,
                    isValid
                } = this.formValidationHelper.validateSelectElement(Element, isValidElement);
                Element.highlight_option_error = highlightOptionError;
                isValidElement = isValid;
            }

            if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID)){
                Element.isParentCalled = true;
                if(Element.is_required){
                    isValidElement=Element.dataSource.length > 0 ?  true :false;
                    errorMessage=Element.dataSource.length > 0 ?  "" :errorMessage;
                }
            }

            return {
                ...Element,
                disabled: IsAnyParentEmpty || formElement.read_only,
                value: !_.isNull(Element.dynamic_configuration) ? dynamicFieldValues : elementValue,
                dynamic_fields_values: !_.isNull(Element.dynamic_configuration) ? dynamicFieldValues : [],
                dynamic_element: dynamicElement,
                dataSource,
                error: errorMessage,
                touched: !_.isEmpty(APIValueURL),
                isValid: isValidElement,
                loadingCells: true
            };
        } 
        

        return Element;
    }

    /**
         * Find if the current element is a parent and call the API
         *
         * @param {IFormElement} formElement.
         * @param {IElementUpdate} element.
         * @return {IFormElement} Element.
         */
    private loadingCellsUpdateElement = (forms: IForm[], formElement: IFormElement, element: IElementUpdate)
        : any => {
        let Element: IFormElement = { ...formElement };
        const loading = false;
        if (_.isEqual(formElement.loadingCells, true)) {
            Element = {
                ...Element,
                loadingCells: false
            };
        }
        if (!_.isEqual(Element.parent_elements.length, 0) && !_.isEqual(Element.parent_elements.indexOf(element.formElement.id), -1) && (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT) || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID))) {
            const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(forms, Element.parent_elements);
            const ElementValue = this.utilHelper.isEmptyValue(element.value) ? null : element.value;
            let callLoading = !_.isNull(ElementValue);
            if (!element.formElement.is_required) {
                callLoading = true;
            }
            if (callLoading && !this.utilHelper.isEmptyValue(Element.api_url)
                && !IsAnyParentEmpty) {
                Element = {
                    ...Element,
                    loadingCells: !loading
                };
            }
        }
            return {
                ...Element,
            };
        }
    }

